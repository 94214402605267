<script setup lang="ts">
import { mdiCog, mdiFinance, mdiHelp, mdiLogout, mdiWallet } from "@mdi/js"
import "./v2.css"
import { useOptionalSessionUser } from "~/composables/currentUser"
import { useIntl } from "~/composables/intl"
import { useAuth, navigateTo } from "#imports"
import { onMounted, effect, ref, watch } from "vue"
import { mapHandler, useSafeQuery } from "~/composables/client"
import { Effect, flow } from "effect"
import { useClarity } from "~/composables/clarity"
import { NonEmptyString255 } from "effect-app/Schema"
import { tickersClient } from "~/composables/clients"
import ErrorBoundary from "~/components/ErrorBoundary.vue"

const sess = useAuth()

const { trans } = useIntl()
// Monitor the access token, if it's about to expire, do a token refresh?
onMounted(() => {
  // TODO: Instead of doing this every hour, do it on api client authentication error
  // and retry the request.. ask the user if we can reload to login?
  setInterval(() => sess.getSession(), 1 * 60 * 60 * 1000)
})

const clarity = useClarity()

// on refresh error, go login
effect(() => {
  if (sess.data.value?.error === "RefreshAccessTokenError") {
    if (!window.confirm(trans("session.expired"))) {
      return
    }
    void sess.signIn("auth0", undefined, {
      // don't display the login prompt if already logged-in at auth0
      prompt: "",
    }) // Force sign in to hopefully resolve error
  }
})
const isDrawerOpen = ref<boolean>(false)

const user = useOptionalSessionUser()

const query = ref<string>("")
const queryToSearch = ref<string>("")

const search = mapHandler(tickersClient.Search, handler =>
  flow(handler, Effect.tap(clarity.emitCustomEventLogError("SecuritySearch"))),
)

const wrappedSearch = {
  ...tickersClient.Search,
  handler: (query: string) =>
    query
      ? search.handler({ query: NonEmptyString255(query) })
      : tickersClient.Highlights.handler,
}

const [searchQuery, securities] = useSafeQuery(wrappedSearch, queryToSearch, {
  refetchOnWindowFocus: false,
  initialData: { items: [] },
  initialDataUpdatedAt: 0,
})

let debounceTimeout: number | undefined

watch(query, () => {
  clearTimeout(debounceTimeout)
  debounceTimeout = window.setTimeout(() => {
    queryToSearch.value = query.value
  }, 500)
})
</script>

<template>
  <v-app>
    <v-navigation-drawer v-model="isDrawerOpen">
      <v-list>
        <v-list-item>
          <NuxtLink :to="{ name: 'securities' }">
            <v-icon :icon="mdiFinance" />
            Securities
          </NuxtLink>
        </v-list-item>
        <v-list-item>
          <NuxtLink :to="{ name: 'portfolio' }">
            <v-icon :icon="mdiWallet" />
            Portfolio
          </NuxtLink>
        </v-list-item>
        <v-list-item>
          <NuxtLink :to="{ name: 'settings' }">
            <v-icon :icon="mdiCog" />
            {{ trans("body.settings") }}
          </NuxtLink>
        </v-list-item>
        <v-list-item>
          <NuxtLink :to="{ name: 'support' }">
            <v-icon :icon="mdiHelp" />
            Support
          </NuxtLink>
        </v-list-item>
        <v-list-item>
          <NuxtLink
            :to="{ name: 'index' }"
            @click="() => (user ? sess.signOut() : sess.signIn('auth0'))"
          >
            <v-icon :icon="mdiLogout" />
            {{ user ? "Logout" : "Login" }}
          </NuxtLink>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <HeaderV2 @toggle-drawer="isDrawerOpen = !isDrawerOpen">
      <SearchSecurity
        v-model:query="query"
        :securities="securities.items"
        :is-loading="searchQuery.waiting ?? false"
        @go-to-security="navigateTo('/securities/' + $event)"
      />
    </HeaderV2>
    <v-main>
      <ErrorBoundary>
        <slot />
      </ErrorBoundary>
    </v-main>
  </v-app>
</template>
